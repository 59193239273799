import React, { Component } from 'react';

// class based component
class Welcome extends Component {
    render() {
        return (
            <h2>Class Component</h2>
        )
    }
}

export default Welcome;