import React from 'react';

const Navbar = () => {
    return (
            React.createElement ('ul',
                                { id : 'HeaderMenu'},
                                React.createElement('li', null, <a href='#'>Home</a>),
                                React.createElement('li', null, <a href='#'>About</a>),
                                React.createElement('li', null, <a href='#'>Service</a>),
                                React.createElement('li', null, <a href='#'>Our Client</a>),
                                React.createElement('li', null, <a href='#'>Blog</a>),
                                React.createElement('li', null, <a href='#'>Career</a>),
                                )
            )
    }

export default Navbar;