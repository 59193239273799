import React from 'react';

// function based component
const Greet = () => 
    <div id="wrapper">
        <div id="left-side"></div>
        <span class="text-teal">Brand New</span>
        <h2 id='heading'>Everything you <br/> can imagine <b>is real</b></h2>
        <p id="subHeading">Welcome to USD Services, where innovation meets excellence in software development, maintenance, enterprise security, and website/app development.</p>
        <button id="getStart">Get started</button>
        <a id="learnMore" href="#"><b>Learn More</b></a>
    </div>
export default Greet;