import ReactDOM from 'react-dom';
import logo from './logo.svg';
import './App.css';
import Greet from './components/Greet';
import Welcome from './components/Welcome';
import Hello from './components/Hello';
import Navbar from './components/Navbar';
import Wblogo from './components/Wblogo';
import BrndNew from './components/Brndnew';

function App() {
  const appName =<h1>React Application</h1>;

  return (
    <div className="App">
      <Wblogo />
      <Navbar />
      <BrndNew />
      <Greet />
    </div>
  );
}

export default App;